import React from 'react'
import '../styles/valueprop.scss'

export default () => {
  return (
    <div class='section card valueprop'>
      <h2>Analytics should not be painful</h2>
      <p class='subtitle'>Our product revolves around 3 values:</p>
      <div class='row'>
        <div class='col'>
          <h3>Simple</h3>
          <p>No more complicated dashboard. Useful information at first glance.</p>
          <a href='https://dash.nucleus.sh/analytics?demo=1'>Demo dashboard →</a>
        </div>
        <div class='col'>
          <h3>Fair</h3>
          <p>We don't sell your data and only collect the strict necessary.</p>
          <a href='/transparency'>What we collect →</a>
        </div>
        <div class='col'>
          <h3>Dev-friendly</h3>
          <p>Modules for the main languages that'll make integrating a breeze.</p>
          <a href='https://docs.nucleus.sh/modules/javascript'>See the docs →</a>
        </div>
      </div>
      {/* <a class='primaryBtn' href='/signup'>Get started →</a> */}
    </div>
  )
}
