import React, { useState } from 'react'
import Prism from 'prismjs'
import '../styles/bigHeader.scss'

const snippets = {}

snippets.javascript = `import Nucleus from "nucleus-analytics"
Nucleus.init("tracking-id")

Nucleus.track("ButtonClicked", {
  action: "Settings"
})`

snippets.python = `import nucleus
nucleus.app_id = "tracking-id"
nucleus.app_started()

nucleus.track("ButtonClicked", {
    "action": "settings"
})`

snippets.swift = `import NucleusSwift

Nucleus.shared.setup("tracking-id")
Nucleus.shared.appStarted()

Nucleus.shared.track("ButtonClicked", [
  "action": "settings"
])
`

snippets.websocket = `Use the Websocket protocol to report JSON data at 
wss://app.nucleus.sh/track/:appId

websocket.send(JSON.stringify({
    "type": "init",
    "machineId": "8bdjk6eak",
    "userId": "Luke_Skywalker",
    "data": { "age": 39 }
}))`

Prism.languages.python = {
  comment: { pattern: /(^|[^\\])#.*?(\r?\n|$)/g, lookbehind: !0 },
  string: /("|')(\\?.)*?\1/g,
  keyword: /\b(as|assert|break|class|continue|def|del|elif|else|except|exec|finally|for|from|global|if|import|in|is|lambda|pass|print|raise|return|try|while|with|yield)\b/g,
  boolean: /\b(True|False)\b/g,
  number: /\b-?(0x)?\d*\.?[\da-f]+\b/g,
  operator: /[-+]{1,2}|=?&lt;|=?&gt;|!|={1,2}|(&){1,2}|(&amp;){1,2}|\|?\||\?|\*|\/|~|\^|%|\b(or|and|not)\b/g,
  ignore: /&(lt|gt|amp);/gi,
  punctuation: /[{}[\];(),.:]/g
}

export default ({ what, description, ogLang, title }) => {
  const [lang, setLang] = useState(ogLang || 'javascript')

  return (
    <div class='bigHeader'>
      <h1 class='lean' dangerouslySetInnerHTML={{ __html: title }} />
      <p class='subtitle' dangerouslySetInnerHTML={{ __html: description }} />
      <pre>
        {!ogLang && (
          <div class='languageSelector'>
            <button
              class={lang === 'javascript' ? 'active' : ''}
              onClick={() => setLang('javascript')}
            >
              JavaScript
            </button>
            <button
              class={lang === 'python' ? 'active' : ''}
              onClick={() => setLang('python')}
            >
              Python
            </button>
            <button
              class={lang === 'swift' ? 'active' : ''}
              onClick={() => setLang('swift')}
            >
              Swift
            </button>
            <button
              class={lang === 'websocket' ? 'active' : ''}
              onClick={() => setLang('websocket')}
            >
              Custom
            </button>
          </div>
        )}
        {lang === 'javascript' && (
          <code
            class='language-javascript'
            dangerouslySetInnerHTML={{
              __html: Prism.highlight(
                snippets.javascript,
                Prism.languages.javascript,
                'javascript'
              )
            }}
          />
        )}
        {lang === 'python' && (
          <code
            class='language-javascript'
            dangerouslySetInnerHTML={{
              __html: Prism.highlight(
                snippets.python,
                Prism.languages.javascript,
                'javascript'
              )
            }}
          />
        )}
        {lang === 'swift' && (
          <code
            class='language-javascript'
            dangerouslySetInnerHTML={{
              __html: Prism.highlight(
                snippets.swift,
                Prism.languages.javascript,
                'javascript'
              )
            }}
          />
        )}
        {lang === 'websocket' && (
          <code
            class='language-javascript'
            dangerouslySetInnerHTML={{
              __html: Prism.highlight(
                snippets.websocket,
                Prism.languages.javascript,
                'websocket'
              )
            }}
          />
        )}
      </pre>
    </div>
  )
}
