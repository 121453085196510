import React from 'react'
import * as Icon from 'react-feather'

import '../styles/features.scss'

export default ({ what }) => {
  return (
    <div className='section'>
      <h1>Features</h1>
      <div class='featuresGrid'>
        <div>
          <Icon.AlertOctagon />
          <h3>Bug reporting</h3>
          <p>Detect bugs as they happen to improve your app accordingly.</p>
        </div>
        <div>
          <Icon.Users />
          <h3>User explorer</h3>
          <p>Identify users and follow their actions through your app.</p>
        </div>
        <div>
          <Icon.Activity />
          <h3>Real-time view</h3>
          <p>See who is actively using your app <i>right now</i> and how.</p>
        </div>
        <div>
          <Icon.FileText />
          <h3>Track events (with data)</h3>
          <p>Track actions in your app with events and remark improbable behaviors.</p>
        </div>
        <div>
          <Icon.WifiOff />
          <h3>Offline support</h3>
          <p>Data will be stored offline and synced as soon as online.</p>
        </div>
        {what === 'electron' && (
          <div>
            <Icon.Package />
            <h3>Designed for Electron</h3>
            <p>Install from NPM and start tracking your app with a tracking id. That easy.</p>
          </div>
        )}
        {what === 'python' && (
          <div>
            <Icon.Package />
            <h3>Designed for Python</h3>
            <p>Install with pip module and start tracking your app with a tracking id. That easy.</p>
          </div>
        )}
        {what === 'swift' && (
          <div>
            <Icon.Package />
            <h3>Designed for Swift</h3>
            <p>Use the Swift package to start tracking your app with a tracking id. That easy.</p>
          </div>
        )}
        {what === 'nodejs' && (
          <div>
            <Icon.Package />
            <h3>Designed for Node.js</h3>
            <p>Install from NPM and start tracking your app with a tracking id. That easy.</p>
          </div>
        )}
        {what === undefined && (
          <div>
            <Icon.Package />
            <h3>Designed for desktop</h3>
            <p>Use one of the SDKs to start tracking your app.</p>
          </div>
        )}
      </div>
    </div>
  )
}
