import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import '../styles/showcase.scss'

export default () => {
  const [selected, setSelected] = useState(1)

  const data = useStaticQuery(graphql`
  query data {
    first: file(relativePath: { eq: "analytics.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    second: file(relativePath: { eq: "bugs.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    third: file(relativePath: { eq: "users.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
  `)

  return (
    <div className='section showcase'>
      <h1>Focus on what matters</h1>
      <div>
        <div class='options'>
          <div onClick={() => setSelected(1)} className={selected === 1 ? 'selected' : null}>
            <h3>Analytics</h3>
            <p>See your users' country, device, language, and more.</p>
          </div>
          <div onClick={() => setSelected(2)} className={selected === 2 ? 'selected' : null}>
            <h3>Error Reporting</h3>
            <p>Users don't tell everything. Get a headstart on bugs and fix them with the stacktrace.</p>
          </div>
          <div onClick={() => setSelected(3)} className={selected === 3 ? 'selected' : null}>
            <h3>Users</h3>
            <p>Get the complete picture on who is using your app.</p>
          </div>
        </div>

        <div class='screenshots'>
          <div>
            {selected === 1 && <Img fluid={data.first.childImageSharp.fluid} />}
            {selected === 2 && <Img fluid={data.second.childImageSharp.fluid} />}
            {selected === 3 && <Img fluid={data.third.childImageSharp.fluid} />}
          </div>
        </div>
      </div>
    </div>
  )
}
